import styled from 'styled-components'
import { Link } from 'gatsby'
import { Col, Row } from 'react-styled-flexboxgrid'
import { ChevronRight } from '@styled-icons/octicons'
import { LocationOn } from '@styled-icons/material'
import { LinkedinSquare } from '@styled-icons/boxicons-logos'
import { TextWrapper } from '../base'
import variables from '../variables'
import { headerStyles } from '../global'
import { Heading } from '../../components'

export const Container = styled.div`
  width: 100%;
`

export const SectionContainer = styled(TextWrapper)`
  margin-top: 30px;
  margin-bottom: 0;
  @media ${variables.media.sm} {
    padding: 40px 130px;
  }
  @media ${variables.media.md} {
    padding: 40px 200px;
  }
`

export const SectionRow = styled(Row)`
  width: 100%;
`
export const SectionCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const HeartImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 200px;
  margin-top: 30px;
  @media ${variables.media.md} {
    width: auto;
    max-width: 100%;
    margin-top: 0;
  }
  
`
export const BannerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media ${variables.media.md} {
    justify-content: flex-start;
    padding: 20px 100px;
  }
`

export const MainHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  color: ${variables.colors.oracle.dark};
`

export const BannerText = styled(Heading)`
  ${headerStyles.headerSmall};
  color: ${variables.colors.jade.medium};
  margin-right: 20px;
  text-align: center;
  @media ${variables.media.md} {
    text-align: left;
`

export const TeamMemberContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const BoardMemberContainer = styled(TeamMemberContainer)`
  margin-bottom: 20px;
`
export const MemberImage = styled.img`
  
`
export const LinkedinIcon = styled(LinkedinSquare)`
  width: 45px;
  height: 45px;
  color: ${variables.colors.oracle.darker};
  margin-right: 5px;
`
export const MemberInfo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  @media ${variables.media.sm} {
    justify-content: flex-start;
  }
`
export const MemberText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${variables.colors.oracle.darker};
  margin-top: 0;
  margin-bottom: 5px;
`

export const Centerer = styled.div`
  display: flex;
  justify-content: center;
`

export const PositionList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 30px 0;

  @media ${variables.media.md} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const Position = styled.li`
  margin-bottom: 15px;
  @media ${variables.media.md} {
    flex-basis: 33%;
    padding-right: 15px;
  }
`

export const PositionSubContainer = styled.div`
  border: 1px solid ${variables.colors.silverTree.medium};
  border-radius: 8px;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const PositionImg = styled.img`
  height: 80px;
`

export const PositionHeader = styled(Heading)`
  ${headerStyles.headerXSmall}
  font-weight: 500;
  font-family: ${variables.fonts.body};
  margin-bottom: 10px;
`

export const PositionIntroduction = styled.p`
  color: #555;
  margin-bottom: 10px;
`

export const PositionLocation = styled.span`
  display: block;
  margin: 0 0 10px 0;
  color: #999;
`

export const PositionLink = styled(Link)``

export const LocationIcon = styled(LocationOn)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  position: relative;
  top: -2px;
  color: #ccc;
`

export const ChevronRightIcon = styled(ChevronRight)`
  width: 16px;
  height: 16px;
  margin-left: 2px;
  position: relative;
  top: -1px;
`


export const OpenApplication = styled.div`
  color: ${variables.colors.oracle.darker};
`
export const BenefitContainer = styled.div`
  padding: 0 40px;
  @media ${variables.media.md} {
    padding: 0 80px;
  }
`
export const BenefitHeader = styled(Heading)`
  ${headerStyles.headerXSmall};
  color: ${variables.colors.oracle.dark};
  text-align: center;
  margin-bottom: 80px;
`
export const BenefitRow = styled(SectionRow)`
  display: flex;
  justify-content: center;
`
export const BenefitCol = styled(Col)`
  margin-bottom: 50px;
`
export const BenefitImg = styled.img`
  width: 50px;
  margin: 0 auto;
  display: block;
`
export const BenefitText = styled.p`
  text-align: center;
  color: ${variables.colors.carnationPink.medium};
`
