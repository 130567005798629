import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { PageLayout } from '../layouts'
import * as styles from '../styles/pages/company'
import {
  Section, PageHeader, SimpleCallToActionBanner, Markdown, Button
} from '../components'
import variables from '../styles/variables'
import heart from '../../assets/icons/heart.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function JoinUsPage({ data }) {
  const { allYaml, allMdx } = data
  const { edges } = allYaml
  const { node } = _.first(edges)

  const firstChild = (
    <PageHeader
      header={node.header}
      info={node.headerInfo}
    />
  )

  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={node.metaDescription}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <Section>
        <styles.Container>
          <styles.SectionContainer>
            <styles.SectionRow>
              <styles.SectionCol sm={7} md={5} xs={12}>
                <styles.MainHeader>{node.introSectionHeader}</styles.MainHeader>
                <Markdown>
                  {node.introSectionText}
                </Markdown>
              </styles.SectionCol>
              <styles.SectionCol sm={1} xs={0} />
              <styles.SectionCol sm={4} md={6} xs={12}>
                <styles.HeartImage src={heart} alt="heart" />
              </styles.SectionCol>
            </styles.SectionRow>
          </styles.SectionContainer>

          <styles.SectionContainer>
            <styles.BannerContainer>
              <styles.BannerText>
                {node.bannerText}
              </styles.BannerText>
              <Link to="/contact-us/">
                <Button size="small">Talk to us</Button>
              </Link>
            </styles.BannerContainer>
          </styles.SectionContainer>

          <SimpleCallToActionBanner />
        </styles.Container>
      </Section>
    </PageLayout>
  )
}

JoinUsPage.propTypes = propTypes

export const contentQuery = graphql`
  query GetCompanyContent {
    allMdx(filter: {frontmatter: { path: { regex: "^/company/join-us/" } }}) {
      edges {
        node {
          frontmatter {
            path
            title
            date
            introduction
            location
            imgLink
            publish
          }
        }
      }
    },

    allYaml(filter: {file: {eq: "company"}}) {
      edges {
        node {
          title
          header
          headerInfo
          metaDescription
          introSectionText

          bannerText
          bannerButtonText
          bannerButtonUrl

          teamDisclaimer

          careerSectionHeader
          careerSectionText
          benefitSectionHeader
          benefits
        }
      }
    }
  }
`
